import img1 from '../../assets/img/project-grid/1.jpg'
import img2 from '../../assets/img/project-grid/2.jpg'
import img3 from '../../assets/img/project-grid/3.jpg'
import img4 from '../../assets/img/project-grid/4.jpg'
import img5 from '../../assets/img/project-grid/5.jpg'
import img6 from '../../assets/img/project-grid/6.jpg'

export const ProjectData = [
    {
        id:1,
        img: img1,
        title: "Data Lakes",
        description:"- We specialize in guiding organizations through the complexities of building Hadoop and other Big Data platforms. Our expertise spans from architecture design to implementation, ensuring optimal configuration for distributed computing, storage, and fault tolerance. With us, businesses navigate the challenges of scaling infrastructure and harnessing the full potential of Big Data technologies to unlock valuable insights and drive strategic initiatives."
    },
    {
        id:2,
        img: img3,
        title: "Cloud Migrations",
        description:"- We offer comprehensive support for your company's cloud migration strategy by providing expert guidance, tailored solutions, and seamless integration processes. Leveraging our extensive experience and cutting-edge technologies, we will ensure a smooth transition to the cloud, optimizing performance, scalability, and cost-efficiency. Our 5holistic approach addresses every aspect of the migration journey, from assessing current infrastructure to designing and implementing robust cloud architectures, ultimately enabling your organization to unlock the full potential of cloud computing while minimizing risks and maximizing returns."
    },
    {
        id:3,
        img: img5,
        title: "Snowflake Datamarts",
        description:"- We specialize in facilitating seamless implementation and scaling of Snowflake data mart solutions for your company. Leveraging our expertise, they streamline the setup process, ensuring optimal architecture and configuration tailored to your specific needs. With a focus on scalability, we will equip your organization with the tools and guidance necessary to efficiently manage and expand your Snowflake environment as your data requirements evolve, empowering you to unlock the full potential of your data assets."
    },
    {
        id:4,
        img: img6,
        title: "On-prem and Cloud Solutions",
        description:"- We offer comprehensive solutions tailored to both on-premises and cloud environments, providing seamless integration and scalability. By leveraging cutting-edge technologies and expertise, PrimeDataInsights enables businesses to optimize their data infrastructure, streamline operations, and unlock valuable insights. Whether it's deploying robust on-premises systems for data security and control or harnessing the flexibility and cost-effectiveness of cloud platforms, we empower companies to harness the full potential of their data assets for sustainable growth and competitive advantage."
    },
    {
        id:5,
        img: img2,
        title: "Data Operations and Governance",
        description:"- We offer tailored solutions for robust data governance, ensuring compliance, security, and data integrity across your organization's ecosystem. Additionally, through democratization initiatives, we empower users at all levels with access to data insights and analytics tools, fostering collaboration and informed decision-making. By implementing effective governance frameworks and promoting data accessibility, PrimeDataInsights facilitates a culture of data-driven innovation and transparency within your organization."
    },
    {
        id:6,
        img: img4,
        title: " Analytics and Business Intelligence",
        description:"- We leverage enterprise Business Intelligence (BI) tools to gather valuable insights for your organization, enabling comprehensive data analysis and visualization. Through advanced analytics capabilities and intuitive reporting features, we help uncover actionable insights from complex datasets, driving informed decision-making and strategic initiatives. By harnessing the power of BI tools, PrimeDataInsights empowers your organization to unlock hidden patterns, trends, and opportunities within your data, ultimately driving efficiency, productivity, and competitive advantage."
    } 
]