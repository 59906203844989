 import React from 'react'

//  Import Img
 import check from '../../../assets/img/about/tick.png'
 import AboutImg from '../../../assets/img/o-home-about-img.png'

 import { Link } from 'react-router-dom';
 
 const AboutOne=()=> {
  return (
   <>
   {/* About One Area Start */}
     <section id="about" className="py100 bg-color">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="left-side-title">
                        <h3>About Company</h3>
                        <h2 className="pt10">
                            We Provide Data Engineering, Cloud (AWS, Azure, GCP), AI/ML, Full Stack Development.
                        </h2>
                    </div>
                    <div className="about-details pt10">
                        <p>
                        At PrimeDataInsights, we understand that data is the lifeblood of modern organizations. However, extracting meaningful information from the vast amounts of data can be a daunting task. That's where we come in. Our team of skilled data experts and technologists are equipped with the latest tools and technologies to help you unlock the true value of your data
                        </p>
                        <ul className="pt20">
                            <li className="wow zoomIn" data-wow-duration="2.0s">
                                <img alt="" src={check}  />Data Architecture and Engineering 
                            </li>
                            <li className="wow zoomIn" data-wow-duration="2.0s">
                                <img alt="" src={check}  />Business Intelligence and Analytics 
                            </li>
                            <li className="wow zoomIn" data-wow-duration="2.0s">
                                <img alt="" src={check}  />Full Stack development 
                            </li>
                            <li className="wow zoomIn" data-wow-duration="2.0s">
                                <img alt="" src={check}  />Data Governance and Compliance
                            </li>
                        </ul>
                        <p></p>
                        {/* <Link to="/#" className="btn btn-theme mt30">How It Work</Link> */}
                        
                        <div className="left-side-title"> 
                        <h5 className="pt10">
                        Welcome to PrimeDataInsights, where data meets innovation and Creativity!
                        </h5>
                    </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="animation-img animation-img-one">
                        <img alt="" src={AboutImg}  />
                    </div>
                </div>
            </div>
        </div>
    </section>
       {/* About One Area End */}
   </>

  )
 }
 
 export default AboutOne
 