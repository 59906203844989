import React from 'react'
// import About Img
// import img1 from '../../assets/img/about-page.png'
// import Icon
import {FiCheck} from 'react-icons/fi';

const History = () => {
    return (
        <>
            {/* About Top Area Start */}
            <section id="about-top" className="py50 bg-color">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="about-img">
                                <h2>History begins in 2010 with the foundation</h2>
                                <p>
                                Welcome to PrimeDataInsights, where data meets innovation and creativity! We are a cutting-edge Data Analytics Company specializing in ETL, Data Engineering, Data Governance, Cloud Migration, AI, and ML. Our mission is to empower businesses with actionable insights and enable them to leverage the full potential of their data.
                                </p>
                                <p>
At PrimeDataInsights, we understand that data is the lifeblood of modern organizations. However, extracting meaningful information from the vast amounts of data can be a daunting task. That's where we come in. Our team of skilled data experts and technologists are equipped with the latest tools and technologies to help you unlock the true value of your data.
</p>
                                <p>
Our core capabilities encompass the entire data lifecycle. Whether you need to streamline your data extraction, transformation, and loading processes (ETL), architect robust data pipelines, establish effective data governance frameworks, migrate your infrastructure to the cloud, or harness the power of AI and ML, PrimeDataInsights has got you covered.
</p>
                                <p>
Here's a glimpse of what sets PrimeDataInsights apart:
</p>
                                <p>
ETL Excellence: Our expertise in ETL processes ensures seamless data integration from diverse sources. We employ industry-leading tools and methodologies to optimize data quality, reduce latency, and enhance overall performance.
Data Engineering Mastery: Our skilled data engineers design and implement scalable and efficient data architectures. We leverage both traditional and modern big data technologies to build robust pipelines that can handle the most complex data requirements.
Data Governance Prowess: We recognize the importance of data governance in maintaining data integrity, privacy, and compliance. Our comprehensive data governance solutions help you establish data policies, standards, and controls, ensuring that your data remains secure and compliant.
Cloud Migration Expertise: Embracing the cloud is essential for modernizing your data infrastructure. We guide you through the entire cloud migration journey, helping you select the right cloud platform, architecting scalable solutions, and ensuring a smooth transition while maximizing cost efficiency.
AI and ML Advancements: We harness the power of artificial intelligence and machine learning to deliver predictive and prescriptive analytics. Our data scientists work closely with your team to develop custom AI models that drive actionable insights, enabling you to make informed business decisions.
At PrimeDataInsights, we believe that data analytics should be accessible and transformative for every organization, regardless of their size or industry. Our customer-centric approach ensures that we tailor our solutions to meet your specific needs, delivering measurable results and enabling you to gain a competitive edge.
                                </p>
                                 
                                <ul>
                                    <li><i><FiCheck/></i>Data MoHadoop and other Big Data Platforms</li>
                                    <li> <i><FiCheck/></i>Strategy and Architecture</li>
                                    <li><i><FiCheck/></i>Cloud Migration Efforts</li>
                                    <li><i><FiCheck/></i>Data Governance, Democratization</li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="about-top-img animation-img-two">
                                <img alt="" src={img1} />
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
            {/* About Top Area Start */}
        </>
    )
}

export default History
