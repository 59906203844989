import React from 'react'
import img from '../../assets/img/project-details/2.png'
import { OurServiceData } from '../Home/OurService/OurServicesData'

const SingleServiceDeatils = (props) => {
    // Find the product with the matching productId
    const selectedService = OurServiceData.find(service => service.id === parseInt(props.serviceId));

    if (!selectedService) {
        // Handle the case where the product is not found (optional)
        return <p>Product not found</p>;
    }

    return (
        <>
            <section id="service-details" className="py100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="service-details-img">
                                <img alt="" src={selectedService.img} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="service-detail-text">
                                <div className="left-side-title">
                                    <h2 className="pt10">{selectedService.heading}</h2>
                                    <p>{selectedService.paragraph}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SingleServiceDeatils
