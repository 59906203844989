import Icon1 from '../../../assets/img/service/1.png'
import Icon2 from '../../../assets/img/service/2.png'
import Icon3 from '../../../assets/img/service/3.png'
import Icon4 from '../../../assets/img/service/4.png'
import Icon5 from '../../../assets/img/service/5.png'
// import Icon6 from '../../../assets/img/service/6.png'

export const OurServiceData = [
    {
        id: 1,
        img: Icon1,
        heading: "Data Modernization Services",
        paragraph: "Make your data more valuable and create advanced data products for intelligent business decisions with the transformative powers of modernized data architecture in the cloud.",
        button: "Read More ..."
    },
    {
        id: 2,
        img: Icon2,
        heading: "Data Platform Management Services",
        paragraph: "We provide end-to-end data platform management ─ right from source integration management to data pipeline line management and API management for data consumers.",
        button: "Read More ..."
    },
    {
        id: 3,
        img: Icon3,
        heading: "Strategy and Architecture",
        paragraph: "Define your data strategy and unlock your competitive advantage.",
        button: "Read More ..."
    },
    {
        id: 4,
        img: Icon4,
        heading: "Manage and Govern Your Data Assets",
        paragraph: "Take advantage of next-generation, data-governance operating models that deliver value.",
        button: "Read More ..."
    },
    {
        id: 5,
        img: Icon5,
        heading: "Enterprise AI Services",
        paragraph: "Leverage the power of AI to accelerate business outcomes. Embed AI technology components and AI-generated insights into your business decisions and processes.",
        button: "Read More ..."
    }
    // ,
    // {
    //  img:Icon6,
    //  heading:"Business Intelligence",
    //  paragraph:"Lorem ipsum dolor sit amet, sed nom consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
    //  button:"Read More ..."
    // }
]
