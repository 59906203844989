import React from 'react'
import CommonBanner from '../component/Common/Banner'
import SingleServiceDetails from '../component/ServiceDetails/SingleServiceDeatils'
import RelatedServices from '../component/ServiceDetails/RelatedServices'
import ServiceGraph from '../component/ServiceDetails/ServiceGraph'
import { useParams } from 'react-router-dom';
import {OurServiceData} from '../component/Home/OurService/OurServicesData'

const ServiceDetails = () => {
    const { serviceId } = useParams();
    
     // Find the product with the matching productId
  const selectedService = OurServiceData.find(service => service.id === parseInt(serviceId));
    return (
        <>
            <CommonBanner heading={selectedService.heading} menu1="Service" menu2={selectedService.heading}/>
            <SingleServiceDetails serviceId={serviceId}/>
            {/* <RelatedServices/> */}
             <ServiceGraph/>
        </>
    )
}

export default ServiceDetails
