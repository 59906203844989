import React from 'react'
import img1 from '../../assets/img/service/service-details1.jpg'
import {BsCheckCircle} from "react-icons/bs";

function ServiceGraph() {
    return (
        <>
            <section id="service-img-details" className="py100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                            <div className="service-img-details">
                                <img alt="" src={img1} />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <div className="service-img-text">
                                <h3>Technologies that we use</h3>
                                <p>We are technology agnostic and focus on the most efficient tool sets to get you valuable data insights. Below are some of the platforms and tools that we have expertise in: </p>
                                <ul>
                                    <li><i><BsCheckCircle/></i>Cloudera, Hortonworks and Other Big Data platforms</li>
                                    <li><i><BsCheckCircle/></i>AWS, Azure, GCP, Snowflake. </li>
                                    <li><i><BsCheckCircle/></i>Informatica, Data Stage, Talend, Spark, Airflow. </li>
                                    <li><i><BsCheckCircle/></i>Tableau, Power BI, Trino.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ServiceGraph
