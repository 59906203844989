import React from 'react'
import CommonBanner from '../component/Common/Banner'
import SingleProject from '../component/ProjectDetails'
import { useParams } from 'react-router-dom';
import {ProjectData} from '../component/Projects/ProjectData'

const ProjectDetails = () => {
    const { productId } = useParams();
    
     // Find the product with the matching productId
  const selectedProduct = ProjectData.find(product => product.id === parseInt(productId));

  if (!selectedProduct) {
    // Handle the case where the product is not found (optional)
    return <p>Product not found</p>;
  }

    return (
        <>
            <CommonBanner heading={selectedProduct.title} menu1="Projects" menu2={selectedProduct.title}/>
            <SingleProject productId={productId}/>
        </>
    )
}

export default ProjectDetails
